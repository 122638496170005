<template>
    <div>
        <validation-observer ref="jobOffer">
            <b-form>
                <b-row>
                    <b-col
                        cols="12"
                        md="12"
                    >
                        <b-card no-body>
                            <b-card-header>
                                <h4 class="card-title">{{ i18nT(`Job offer`) }}</h4>
                                <b-dropdown
                                    variant="link"
                                    toggle-class="p-0"
                                    no-caret
                                    right
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                        />
                                    </template>
                                    <b-dropdown-item
                                        :href="publicUrl"
                                        @click="onAddNewJobOfferSubmit()"
                                        target="_blank"
                                    >
                                        <feather-icon icon="EyeIcon"/>
                                        <span class="align-middle ml-50">{{ i18nT(`Preview`) }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="onCopy()">
                                        <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="onAddNewJobOfferSubmit()">
                                        <span class="align-middle ml-50">{{ i18nT(`Save`) }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        v-if="hasRouteId"
                                        @click="onDelete()"
                                    >
                    <span
                        class="align-middle ml-50"
                        style="color: red"
                    >{{ i18nT(`Delete`) }}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-card-header>
                            <b-card-body>
                                <b-row class="border-bottom pb-2">
                                    <!-- Field: Username -->
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>{{ i18nT(`Job details`) }}</h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Title`)"
                                                    label-for="offerLabel"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Title`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="offerLabel"
                                                            name="offerLabel"
                                                            id="offerLabel"
                                                            :state="errors.length > 0 ? false : null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Job category`)"
                                                    label-for="offerCategory"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Job category`)"
                                                        rules="required"
                                                    >
                                                        <v-select
                                                            id="offerCategory"
                                                            v-model="offerCategory"
                                                            :options="offerCategories"
                                                            label="text"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Department`)"
                                                    label-for="offerDepartment"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Department`)"
                                                        rules="required"
                                                    >
                                                        <v-select
                                                            id="offerDepartment"
                                                            v-model="offerDepartment"
                                                            :options="offerDepartments"
                                                            label="text"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Contract type`)"
                                                    label-for="offerContractType"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Contract type`)"
                                                        rules="required"
                                                    >
                                                        <v-select
                                                            id="offerContractType"
                                                            v-model="offerContractType"
                                                            :options="offerContractTypes"
                                                            label="text"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12">
                                                <b-form-group
                                                    :label="i18nT(`Job description`)"
                                                    label-for="offerDescription"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Job description`)"
                                                        rules="required"
                                                    >
                                                        <quill-editor
                                                            id="offerDescription"
                                                            ref="offerDescription"
                                                            rows="10"
                                                            v-model="offerDescription"
                                                            :state="errors.length > 0 ? false:null"
                                                            :options="snowOption"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                                <div class="d-flex justify-content-end">
                                                    <small v-if="isAIEnhanced" class="text-muted mt-1 mb-0">{{ i18nT(`Note: To enable AI enhancement again please start over`) }}</small>
                                                    <small v-else class="text-muted mt-1 mb-0">{{ i18nT(`Note: To enable AI please provide a minimum of 150 characters`) }} <br>
                                                    {{ i18nT(`Counter: `) + parseHtmlToText(offerDescription).length}}</small>
                                                    <b-button :disabled="isAIEnhanced || parseHtmlToText(offerDescription).length < 150"
                                                        variant="primary" 
                                                        @click="enhanceWithAI"
                                                        class="ml-1"
                                                        >{{ i18nT(`Enhance with AI`) }}
                                                    </b-button>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <b-row class="border-bottom py-2">
                                    <!-- Field: Username -->
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>{{ i18nT(`Required profile`) }}</h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Seniority level`)"
                                                    label-for="offerSeniorityLevel"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Seniority level`)"
                                                        rules="required"
                                                    >
                                                        <v-select
                                                            id="offerSeniorityLevel"
                                                            v-model="offerSeniorityLevel"
                                                            :options="offerSeniorityLevels"
                                                            label="text"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Required skills`)"
                                                    label-for="offerRequiredSkills"
                                                >
                                                    <voerro-tags-input
                                                        element-id="tags"
                                                        v-model="offerRequiredSkills"
                                                        :existing-tags="offerJobSkills"
                                                        textField="text"
                                                        idField="value"
                                                        :placeholder="i18nT(`Type and hit enter to add the skill`)"
                                                        :typeahead="true"
                                                    >
                                                    </voerro-tags-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12">
                                                <b-form-group
                                                    :label="i18nT(`Required profile description`)"
                                                    label-for="offerAdditional"
                                                >
                                                    <quill-editor
                                                        id="offerAdditional"
                                                        rows="8"
                                                        v-model="offerAdditional"
                                                        :options="snowOption"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <b-row class="border-bottom py-2">
                                    <!-- Field: Username -->
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>{{ i18nT(`Job compensation`) }}</h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-row>

                                            <b-col
                                                cols="6"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Currency`)"
                                                    label-for="offerCurrency"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Currency`)"
                                                        rules="required"
                                                    >
                                                        <v-select
                                                            v-model="offerCurrency"
                                                            :options="currencies"
                                                            :state="errors.length > 0 ? false : null"
                                                            label="text"
                                                            id="Currency"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="6"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Salary Frequency`)"
                                                    label-for="SalaryPeriod"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Salary Frequency`)"
                                                        rules="required"
                                                    >
                                                        <v-select
                                                            v-model="offerSalaryPeriod"
                                                            :options="salaryPeriods"
                                                            :state="errors.length > 0 ? false : null"
                                                            label="label"
                                                            id="SalaryPeriod"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Salary from`)"
                                                    label-for="offerSalaryFrom"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Salary from`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="offerSalaryFrom"
                                                            name="offerSalaryFrom"
                                                            id="offerSalaryFrom"
                                                            :state="errors.length > 0 ? false : null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Salary to`)"
                                                    label-for="offerSalaryTo"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Salary to`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="offerSalaryTo"
                                                            name="offerSalaryTo"
                                                            id="offerSalaryTo"
                                                            :state="errors.length > 0 ? false : null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Equity from`)"
                                                    label-for="offerEquityFrom"
                                                >
                                                    <b-form-input
                                                        v-model="offerEquityFrom"
                                                        type="number"
                                                        min="0"
                                                        name="offerEquityFrom"
                                                        id="offerEquityFrom"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Equity to`)"
                                                    label-for="offerEquityTo"
                                                >
                                                    <b-form-input
                                                        v-model="offerEquityTo"
                                                        type="number"
                                                        min="2"
                                                        name="offerEquityTo"
                                                        id="offerEquityTo"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <b-col
                                                cols="12"
                                                class="mt-1"
                                            >
                                                <b-form-checkbox
                                                    name="offerSalaryConfidential"
                                                    id="offerSalaryConfidential"
                                                    switch
                                                    :checked="offerSalaryConfidential"
                                                    v-model="offerSalaryConfidential"
                                                >
                                                    {{ i18nT(`Activate if you want to keep salary information confidential.`) }}
                                                </b-form-checkbox>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                class="mt-1"
                                            >
                                                <b-form-checkbox
                                                    name="offerSponsorVisa"
                                                    id="offerSponsorVisa"
                                                    switch
                                                    :checked="offerSponsorVisa"
                                                    v-model="offerSponsorVisa"
                                                >
                                                    {{ i18nT(`Activate if you are willing to sponsor visas.`) }}
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <b-row class="border-bottom py-2">
                                    <!-- Field: Username -->
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>{{ i18nT(`Job location`) }}</h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="6"
                                                class="mb-1"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Do you accept remote working?`)"
                                                    label-for="offerRemote"
                                                >
                                                    <v-select
                                                        id="offerRemote"
                                                        v-model="offerRemote"
                                                        :options="offerRemoteOptions"
                                                        label="text"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12">
                                                <b-form-group
                                                    :label="i18nT(`Address`)"
                                                    label-for="offerAddress"
                                                    class="required"
                                                    v-if="offerRemote.value !== 2"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Address`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="offerAddress"
                                                            name="offerAddress"
                                                            id="offerAddress"
                                                            :state="errors.length > 0 ? false : null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>

                                                <b-form-group
                                                    :label="i18nT(`Address`)"
                                                    label-for="offerAddress"
                                                    v-else
                                                >
                                                    <b-form-input
                                                        v-model="offerAddress"
                                                        name="offerAddress"
                                                        id="offerAddress"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"

                                            >
                                                <b-form-group
                                                    :label="i18nT(`City`)"
                                                    label-for="offerCity"
                                                    class="required"
                                                    v-if="offerRemote.value !== 2"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`City`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="offerCity"
                                                            name="offerCity"
                                                            id="offerCity"
                                                            :state="errors.length > 0 ? false : null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                                <b-form-group
                                                    :label="i18nT(`City`)"
                                                    label-for="offerCity"
                                                    v-else
                                                >
                                                    <b-form-input
                                                        v-model="offerCity"
                                                        name="offerCity"
                                                        id="offerCity"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Country`)"
                                                    label-for="offerCountry"
                                                    class="required"
                                                    v-if="offerRemote.value !== 2"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Country`)"
                                                        rules="required"
                                                    >
                                                        <v-select
                                                            id="offerCountry"
                                                            v-model="offerCountry"
                                                            :options="offerCountries"
                                                            label="text"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                                <b-form-group
                                                    :label="i18nT(`Country`)"
                                                    label-for="offerCountry"
                                                    class="required"
                                                    v-else
                                                >
                                                    <v-select
                                                        id="offerCountry"
                                                        v-model="offerCountry"
                                                        :options="offerCountries"
                                                        label="text"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <b-row class="border-bottom py-2">
                                    <!-- Field: Username -->
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>{{ i18nT(`Employee benefits`) }}</h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-row>
                                            <b-col cols="12">
                                                <b-form-group
                                                    :label="i18nT(`Employee benefits`)"
                                                    label-for="offerBenefits"
                                                >
                                                    <b-form-textarea
                                                        id="offerBenefits"
                                                        rows="4"
                                                        v-model="offerBenefits"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>



                                <b-row class="border-bottom py-2">
                                    <!-- Field: Username -->
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>{{ i18nT(`Additional candidate information`) }}</h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-row>
                                            <b-col cols="12">
                                                <h5>{{ i18nT(`Additional files to attach`) }}</h5>
                                            </b-col>

                                            <b-col
                                                cols="12"
                                                md="6"
                                                class="mt-1"
                                            >
                                                <b-form-checkbox
                                                    name="offerNeedPortfolio"
                                                    id="offerNeedPortfolio"
                                                    switch
                                                    :checked="offerNeedPortfolio"
                                                    v-model="offerNeedPortfolio"
                                                >
                                                    <feather-icon
                                                        icon="PaperclipIcon"
                                                        size="16"
                                                    />
                                                    {{ i18nT(`Require CV`) }}
                                                </b-form-checkbox>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                                class="mt-1"
                                            >
                                                <b-form-checkbox
                                                    name="offerNeedCoverLetter"
                                                    id="offerNeedCoverLetter"
                                                    switch
                                                    :checked="offerNeedCoverLetter"
                                                    v-model="offerNeedCoverLetter"
                                                >
                                                    <feather-icon
                                                        icon="PaperclipIcon"
                                                        size="16"
                                                    />
                                                    {{ i18nT(`Require cover letter`) }}
                                                </b-form-checkbox>
                                            </b-col>

                                            <b-col cols="12">
                                                <h5 class="mt-2">{{ i18nT(`Additional required social links`) }}</h5>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                                class="mt-1"
                                            >
                                                <b-form-checkbox
                                                    name="offerNeedYoutube"
                                                    id="offerNeedYoutube"
                                                    switch
                                                    :checked="offerNeedYoutube"
                                                    v-model="offerNeedYoutube"
                                                >
                                                    <span class="icomoon-youtube"></span> YouTube
                                                </b-form-checkbox>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                                class="mt-1"
                                            >
                                                <b-form-checkbox
                                                    name="offerNeedTwitter"
                                                    id="offerNeedTwitter"
                                                    switch
                                                    :checked="offerNeedTwitter"
                                                    v-model="offerNeedTwitter"
                                                >
                                                    <span class="icomoon-twitter"></span> Twitter
                                                </b-form-checkbox>
                                            </b-col>
                                            <b-col cols="12" md="6" class="mt-1">
                                              <b-form-checkbox
                                                  name="offerNeedInstagram"
                                                  id="offerNeedInstagram"
                                                  switch
                                                  :checked="offerNeedInstagram"
                                                  v-model="offerNeedInstagram"
                                              >
                                              <span class="icomoon-instagram"></span> Instagram
                                              </b-form-checkbox>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                                class="mt-1"
                                            >
                                                <b-form-checkbox
                                                    name="offerNeedBehance"
                                                    id="offerNeedBehance"
                                                    switch
                                                    :checked="offerNeedBehance"
                                                    v-model="offerNeedBehance"
                                                >
                                                    <span class="icomoon-behance"></span> Behance
                                                </b-form-checkbox>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                                class="mt-1"
                                            >
                                                <b-form-checkbox
                                                    name="offerNeedDribble"
                                                    id="offerNeedDribble"
                                                    switch
                                                    :checked="offerNeedDribble"
                                                    v-model="offerNeedDribble"
                                                >
                                                    <span class="icomoon-dribbble"></span> Dribbble
                                                </b-form-checkbox>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                                class="mt-1"
                                            >
                                                <b-form-checkbox
                                                    name="offerNeedGithub"
                                                    id="offerNeedGithub"
                                                    switch
                                                    :checked="offerNeedGithub"
                                                    v-model="offerNeedGithub"
                                                >
                                                    <span class="icomoon-github"></span> GitHub
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>


                                <!-- FOLLOWERS -->
                                <!-- ADDITIONAL QUESTIONS -->
                                <b-row class="border-bottom py-2">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>{{ i18nT(`Additional questions for the candidate`) }}</h5>
                                    </b-col>

                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <draggable>
                                            <b-list-group-item
                                                v-for="(oneQuestion, index) in offerQuestions"
                                                :key="index"
                                                tag="li"
                                            >
                                                <b-row>
                                                    <b-col
                                                        cols="10"
                                                        class="mt-1"
                                                    >
                                                        <b-form-group
                                                            :label="i18nT(`Question text`)"
                                                            label-for="Question text"
                                                        >

                                                            <b-form-input
                                                                v-model="offerQuestions[index].text"
                                                                :placeholder="i18nT(`Type-in the question (single line)`)"
                                                            />

                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col
                                                        cols="2"
                                                        class="mt-3"
                                                    >
                                                        <span class="mt-2"
                                                            @click="deleteQuestionRow(index)">
                                                            <feather-icon
                                                                icon="TrashIcon"
                                                                size="16"
                                                            />
                                                        </span>
                                                    </b-col>
                                                </b-row>
                                            </b-list-group-item>
                                        </draggable>



                                        <b-row v-for="(oneQuestion, index) in offerQuestions" :key="index">

                                        </b-row>

                                        <b-row>
                                            <b-col
                                                cols="12"
                                                class="mt-2"
                                            >
                                                <b-button
                                                    variant="primary"
                                                    size=""
                                                    @click="addQuestionRow"
                                                >
                                                    <feather-icon
                                                        icon="PlusCircleIcon"
                                                        size="16"
                                                    />
                                                    {{i18nT(`Add new`)}}
                                                </b-button>
                                            </b-col>
                                        </b-row>

                                    </b-col>
                                </b-row>


                                <b-row class="border-bottom pb-2">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5 class="mt-2">{{ i18nT(`Followers`) }}</h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                class="mt-2"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Followers`)"
                                                    label-for="Followers"
                                                >
                                                    <v-select
                                                        v-model="offerFollowers"
                                                        multiple
                                                        label="text"
                                                        id="followers"
                                                        :options="offerFollowersOptions"
                                                    />

                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <b-row class="border-bottom pb-2">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5 class="mt-2">{{ i18nT(`Offer status`) }}</h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                class="mt-2"
                                            >
                                                <b-form-checkbox
                                                    name="offerPublic"
                                                    id="offerPublic"
                                                    switch
                                                    :checked="offerPublic"
                                                    v-model="offerPublic"
                                                >
                                                    {{ offerPublic ? i18nT(`Active`) : i18nT(`Inactive`) }}
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <div style="flex: 1"/>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="primary"
                                        class="mt-0 mt-md-2 ml-1 mr-1"
                                        @click.prevent="onAddNewJobOfferSubmit"
                                    >
                                        {{ i18nT(`Save`) }}
                                    </b-button>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import {
    BButton,
    // BButtonGroup,
    // BAlert,
    // BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormCheckbox,
    // BFormCheckboxGroup,
    BFormTextarea,
    BCardBody,
    BCardHeader,
    BDropdown,
    BDropdownItem,
    // BFormSelect,
    // BInputGroup,
    // BInputGroupText,
    // BFormRadio,
    BListGroupItem,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import {quillEditor} from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
// import useAuth from "@/auth/useAuth";
import {dictToSelectArray} from '@core/utils/utils'
import VoerroTagsInput from '@voerro/vue-tagsinput'
import {useUtils as useI18Utils} from "@core/libs/i18n";
import useAuth from '@/auth/useAuth'

const {i18n} = useI18Utils();

// import draggable from "vuedraggable";

export default {
    components: {
        BButton,
        // BButtonGroup,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BCard,
        BCardBody,
        vSelect,
        BFormTextarea,
        // BFormSelect,
        // BAlert,
        // BBadge,
        BFormCheckbox,
        // BFormCheckboxGroup,
        ValidationProvider,
        ValidationObserver,
        // BInputGroup,
        // BInputGroupText,
        // BFormRadio,
        BListGroupItem,
        draggable,
        quillEditor,
        VoerroTagsInput,
        BCardHeader,
        BDropdown,
        BDropdownItem,
    },
    directives: {
        Ripple
    },
    data() {
        return {
            required,
            isAIEnhanced: false,
            language: null,
            offerCategories: [],
            offerContractTypes: [],
            offerSeniorityLevels: [],
            offerDepartments: [],
            offerJobSkills: [],
            offerCountries: [],
            offerFollowers: [],
            offerFollowersOptions: [],
            offerCategory: null,
            offerDepartment: null,
            offerContractType: null,
            offerSeniorityLevel: null,
            offerRequiredSkills: [],
            offerLabel: '',
            offerDescription: '',
            offerAddress: '',
            offerCity: '',
            offerCountry: '',
            offerSalaryFrom: '',
            offerSalaryTo: '',
            offerEquityFrom: '',
            offerEquityTo: '',
            offerCurrency: '',
            offerSalaryPeriod: {label: i18n(`Monthly`), value: 'monthly'},
            offerSalaryConfidential: false,
            offerSponsorVisa: false,
            offerRemote: 0,
            offerBenefits: '',
            offerAdditional: '',
            offerNeedCoverLetter: false,
            offerNeedPortfolio: false,
            offerNeedYoutube: false,
            offerNeedTwitter: false,
            offerNeedInstagram: false,
            offerNeedBehance: false,
            offerNeedDribble: false,
            offerNeedGithub: false,
            offerQuestions: [],
            questionTypes: [],
            question: null,
            questionType: {value: '1'},
            questionLabel: '',
            questionDescription: '',
            answersData: [''],
            linesCnt: 1,
            possibleAnswers: 1,
            otherOption: false,
            steps: 0,
            stepOptions: [2, 3, 5, 10],
            stepStrings: [null, null, null, null, null, null, null, null, null, null],
            firstStep: '',
            lastStep: '',
            grading: 0,
            snowOption: {
                theme: 'snow',
                placeholder: '',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'link'],
                        [{list: 'ordered'}, {list: 'bullet'}],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['clean']
                    ]
                }
            },
            publicUrl: '',
            offerPublic: false,
            currencies: [],
            salaryPeriods: [
                {value: 'daily', label: i18n(`Daily`)},
                {value: 'monthly', label: i18n(`Monthly`)},
                {value: 'yearly', label: i18n(`Annual`)}
            ]
        }
    },
    watch: {
        offerDescription(val) {
            console.log("Offer: ", this.parseHtmlToText(val))
            const offerDescriptionLength = this.parseHtmlToText(val).length
            if(offerDescriptionLength < 40) {
                this.isAIEnhanced = false
            }
            if(offerDescriptionLength === 0) {
                this.offerDescription = ''
            }
        },
        file: function (val) {
            if (val) {
                this.file = val
                this.fileName = val.name
            } else {
                this.file = null
                this.fileName = ''
            }
        }
    },
    async created() {
        let self = this

        const currentLanguage = useAuth.getLocale();

        let cats = this.$http.get(`system/jobCategories`)
        let contractTypes = this.$http.get(`system/contractTypes`)
        let seniorityLvls = this.$http.get(`system/seniorityLevels`)
        let offerDepts = this.$http.get(`system/departments`)
        let jobSkills = this.$http.get(`offers/jobSkills?type=2`)
        let offerCountries = this.$http.get(`system/countries`)
        let followerOptions = this.$http.get(`offers/followersList`)
        let cultures = this.$http.get(`system/cultures`)

        await Promise.all([
            cats,
            contractTypes,
            seniorityLvls,
            offerDepts,
            jobSkills,
            offerCountries,
            followerOptions,
            cultures
        ]).then(function (responses) {
            self.offerCategories = dictToSelectArray(responses[0].data.data)
            self.offerContractTypes = dictToSelectArray(responses[1].data.data)
            self.offerSeniorityLevels = dictToSelectArray(responses[2].data.data)
            self.offerDepartments = dictToSelectArray(responses[3].data.data)
            self.offerJobSkills = responses[4].data.data.map(d => ({
                value: d.id + '',
                text: d.label
            }))
            self.offerCountries = dictToSelectArray(responses[5].data.data)
            console.log("Resp 6", responses[6].data.data)
            self.offerFollowersOptions = responses[6].data.data.map(d => ({
                value: d.Id + '',
                text: d.Label
            }))
            self.language = responses[7].data.data[currentLanguage]
        })

        await this.$http.get(`surveys/questionTypes`).then(({data}) => {
            const questionTypes = data.data
            for (const property in questionTypes) {
                this.questionTypes.push({
                    value: property,
                    text: questionTypes[property]
                })
            }
        })

        await this.$http.get(`system/currencies`).then(({data}) => {
            this.currencies = dictToSelectArray(data.data)
        })

        const id = this.$route.params.id
        if (id !== 'new') {
            await this.$http.get(`offers?id=${id}`).then(({data}) => {
                if (data.data) {
                    let jobOffer = data.data.offer
                    this.offerLabel = jobOffer.Label
                    this.offerAddress = jobOffer.Address
                    this.offerCity = jobOffer.City
                    this.offerDescription = jobOffer.Description
                    this.offerBenefits = jobOffer.Benefits
                    this.offerQuestions = jobOffer.Questions ? jobOffer.Questions : []
                    this.offerAdditional = jobOffer.Additional
                    this.offerFollowers = jobOffer.Followers.map(follower => ({
                        value: follower.Id,
                        text: follower.Label
                    }))
                    this.offerNeedBehance = jobOffer.NeedBehance ? true : false
                    this.offerNeedCoverLetter = jobOffer.NeedCoverLetter ? true : false
                    this.offerNeedDribble = jobOffer.NeedDribble ? true : false
                    this.offerNeedGithub = jobOffer.NeedGithub ? true : false
                    this.offerNeedPortfolio = jobOffer.NeedPortfolio ? true : false
                    this.offerNeedTwitter = jobOffer.NeedTwiter ? true : false
                    this.offerNeedYoutube = jobOffer.NeedYoutube ? true : false
                    this.offerSalaryConfidential = jobOffer.SalaryConfidential
                        ? true
                        : false
                    this.offerSponsorVisa = jobOffer.SponsorVisa ? true : false
                    this.offerRemote = this.offerRemoteOptions.find(
                        c => c.value === jobOffer.Remote
                    )
                    if(!this.offerRemote){
                        this.offerRemote = this.offerRemoteOptions[0];
                    }
                    // console.log("Data from resp", jobOffer.Remote);
                    // console.log("Offer remote",this.offerRemote);
                    this.offerSalaryFrom = jobOffer.SalaryFrom
                    this.offerSalaryTo = jobOffer.SalaryTo
                    this.offerSalaryPeriod = this.salaryPeriods.find(c => c.value === jobOffer.SalaryFrequency)
                    this.offerEquityFrom = jobOffer.EquityFrom
                    this.offerEquityTo = jobOffer.EquityTo
                    this.offerCurrency = this.currencies.find(c => c.value === jobOffer.Currency)
                    this.offerPublic = jobOffer.PublicationStatus === 'ACTIVE'
                    this.offerCategory = this.offerCategories.find(
                        c => c.value === jobOffer.Category.toString()
                    )
                    this.offerDepartment = this.offerDepartments.find(
                        c => c.value === jobOffer.Department.toString()
                    )
                    this.offerContractType = this.offerContractTypes.find(
                        c => c.value === jobOffer.ContractType.toString()
                    )
                    this.offerSeniorityLevel = this.offerSeniorityLevels.find(
                        c => c.value === jobOffer.SeniorityLevel.toString()
                    )
                    this.offerRequiredSkills = dictToSelectArray(jobOffer.RequiredSkills)
                    this.offerCountry = this.offerCountries.find(
                        c => c.value === jobOffer.Country
                    )
                    this.publicUrl = `${window.location.protocol}//${
                        window.location.host
                    }/apply/${jobOffer.Label.split(' ').join('-')}_${jobOffer.Id}`
                }
            })
        }
    },
    computed: {
        hasRouteId() {
            return router && router.currentRoute.params.id
        },
        offerRemoteOptions() {
            return [
                {value: 0, text: this.i18nT(`No, no remote work allowed`)},
                {value: 1, text: this.i18nT(`Yes, occasionally`)},
                {value: 2, text: this.i18nT(`Yes, full remote allowed`)}
            ]
        }
    },
    methods: {
        parseHtmlToText(value) {
            return value.replace(/<[^>]*>/g, '');
        },
        enhanceWithAI() {
            this.$store.commit('app/SET_OVERLAY_MESSAGE', this.i18nT(`AI is thinking...`));
            this.$http.post(`offers/writeJobOffer`, {
                offer_text: this.offerDescription,
                language: this.language
            }).then(({data}) => {
                this.isAIEnhanced = true
                this.offerDescription = data.data
                console.log("Data: ", data)
            }).catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(err.response.data.message),
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                })
            })
        },
        deleteQuestionRow(index) {
            this.offerQuestions.splice(index, 1)
        },
        addQuestionRow() {
            this.offerQuestions.push({
                id: null,
                text: '',
            })
        },
        getOfferCategories() {
            this.$http.get(`system/jobCategories`).then(({data}) => {
                this.offerCategories = dictToSelectArray(data.data)
            })
        },
        getOfferContractTypes() {
            this.$http.get(`system/contractTypes`).then(({data}) => {
                this.offerContractTypes = dictToSelectArray(data.data)
            })
        },
        getOfferSeniorityLevels() {
            this.$http.get(`system/seniorityLevels`).then(({data}) => {
                this.offerSeniorityLevels = dictToSelectArray(data.data)
            })
        },
        getOfferDepartments() {
            this.$http.get(`system/departments`).then(({data}) => {
                this.offerDepartments = dictToSelectArray(data.data)
            })
        },
        getOfferJobSkills() {
            this.$http.get(`offers/jobSkills?type=2`).then(({data}) => {
                this.offerJobSkills = data.data.map(d => ({
                    value: d.id + '',
                    text: d.label
                }))
            })
        },
        getOfferCountries() {
            this.$http.get(`system/countries`).then(({data}) => {
                this.offerCountries = dictToSelectArray(data.data)
            })
        },
        onAddNewJobOfferSubmit() {
            // Prevent modal from closing
            const id = this.$route.params.id

            this.$refs.jobOffer
                .validate()
                .then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.jobOffer.errors)
                                .map(([key, value]) => ({key, value}))
                                .filter(error => error["value"].length);
                            this.$refs.jobOffer.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100)
                        return
                    }
                    // eslint-disable-next-line
                    let curlang = this.$i18n.locale
                    const formData = new FormData()
                    if (id !== 'new') formData.append('id', id)

                    formData.append('Label', this.offerLabel)
                    formData.append('Category', parseInt(this.offerCategory.value))
                    formData.append('Department', this.offerDepartment.value)
                    formData.append('ContractType', this.offerContractType.value)
                    formData.append('SeniorityLevel', this.offerSeniorityLevel.value)
                    formData.append('followers', this.offerFollowers.map(follower => follower.value).join(','))
                    formData.append(
                        'offer_skills',
                        this.offerRequiredSkills
                            .map(s => (s.value ? s.value : s.text))
                            .join()
                    )
                    formData.append('Description', this.offerDescription)
                    formData.append('Address', this.offerAddress)
                    formData.append('City', this.offerCity)
                    formData.append('Country', this.offerCountry.value)
                    formData.append('SalaryFrom', this.offerSalaryFrom)
                    formData.append('SalaryTo', this.offerSalaryTo)
                    formData.append('SalaryFrequency', this.offerSalaryPeriod.value)
                    formData.append('EquityFrom', this.offerEquityFrom)
                    formData.append('EquityTo', this.offerEquityTo)
                    formData.append('Currency', this.offerCurrency.value)
                    formData.append('Benefits', this.offerBenefits)
                    formData.append('Questions', JSON.stringify(this.offerQuestions))
                    formData.append('Additional', this.offerAdditional)
                    formData.append(
                        'SalaryConfidential',
                        this.offerSalaryConfidential ? 1 : 0
                    )
                    formData.append('SponsorVisa', this.offerSponsorVisa ? 1 : 0)
                    // if (this.offerRemote && this.offerRemote.value !== null) {
                        formData.append('Remote', this.offerRemote.value)
                    // }
                    formData.append('NeedCoverLetter', this.offerNeedCoverLetter ? 1 : 0)
                    formData.append('NeedPortfolio', this.offerNeedPortfolio ? 1 : 0)
                    formData.append('NeedYoutube', this.offerNeedYoutube ? 1 : 0)
                    formData.append('NeedTwiter', this.offerNeedTwitter ? 1 : 0)
                    formData.append('NeedInstagram', this.offerNeedInstagram ? 1 : 0)
                    formData.append('NeedBehance', this.offerNeedBehance ? 1 : 0)
                    formData.append('NeedDribble', this.offerNeedDribble ? 1 : 0)
                    formData.append('NeedGithub', this.offerNeedGithub ? 1 : 0)
                    formData.append(
                        'PublicationStatus',
                        this.offerPublic ? 'ACTIVE' : 'DEACTIVATED'
                    )

                    formData.append('lang', curlang)

                    this.$http
                        .post('offers', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            router.push({name: 'job-offers'})
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'InfoIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                })
                .catch(error => {
                    console.log(error)
                })
        },
        onAddNewQuestionClick() {
            this.$bvModal.show('modal-question')
        },
        onAddNewAnswerClick() {
            this.answersData.push('')
        },
        onDeleteAnswerClick(idx) {
            this.answersData.splice(idx, 1)
        },
        updateAnswerOrder() {
            console.log(this.answersData)
        },
        onFilePick: function () {
            this.$refs['filePicker'].$refs.input.click()
        },
        onFileDelete: function () {
            this.file = null
            this.fileName = ''
        },
        getTypeName(typeId) {
            const questionType = this.questionTypes.find(type => type.value == typeId)
            return questionType ? questionType.text : ''
        },
        onDelete() {
            this.$swal({
                title: 'Are you sure you want to delete this entry?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`offers?id=${this.$route.params.id}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refetchData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

/* The input */
.tags-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.tags-input input {
    flex: 1;
    background: transparent;
    border: none;
}

.tags-input input:focus {
    outline: none;
}

.tags-input input[type='text'] {
    color: #495057;
}

.tags-input-wrapper-default {
    padding: 0.5em 0.25em;

    background: #fff;

    border: 1px solid transparent;
    border-radius: 0.25em;
    border-color: #dbdbdb;
}

.tags-input-wrapper-default.active {
    border: 1px solid #8bbafe;
    box-shadow: 0 0 0 0.2em rgba(13, 110, 253, 0.25);
    outline: 0 none;
}

/* The tag badges & the remove icon */
.tags-input span {
    margin-right: 0.3em;
    margin-top: 3px;
}

.tags-input-remove {
    cursor: pointer;
    position: absolute;
    display: inline-block;
    right: 0.3em;
    top: 0.3em;
    padding: 0.5em;
    overflow: hidden;
}

.tags-input-remove:focus {
    outline: none;
}

.tags-input-remove:before,
.tags-input-remove:after {
    content: '';
    position: absolute;
    width: 75%;
    left: 0.15em;
    background: #5dc282;

    height: 2px;
    margin-top: -1px;
}

.tags-input-remove:before {
    transform: rotate(45deg);
}

.tags-input-remove:after {
    transform: rotate(-45deg);
}

/* Tag badge styles */
.tags-input-badge {
    position: relative;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tags-input-badge-pill {
    padding-right: 1.25em;
    padding-left: 0.6em;
    border-radius: 10em;
}

.tags-input-badge-pill.disabled {
    padding-right: 0.6em;
}

.tags-input-badge-selected-default {
    color: #212529;
    background-color: #f0f1f2;
}

/* Typeahead */
.typeahead-hide-btn {
    color: #999 !important;
    font-style: italic;
}

/* Typeahead - badges */
.typeahead-badges > span {
    margin-top: 0.5em;
}

.typeahead-badges > span {
    cursor: pointer;
    margin-right: 0.3em;
}

/* Typeahead - dropdown */
.typeahead-dropdown {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 1000;
}

.typeahead-dropdown li {
    padding: 0.25em 1em;
    cursor: pointer;
}

</style>
